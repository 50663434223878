<template>
  <div class="invoices" v-if="hasPerm('payments.view_invoice')">
    <page-header
      :title="paid ? 'Factures payées' : ('Factures à payer au ' + today)"
      icon="fa fa-file-invoice"
      :links="getLinks()"
    ></page-header>
    <div v-if="!isLoading(loadingName)">
      <b-row>
        <b-col>
          <h2>
            <counter-label :counter="objectsCount" label="facture" v-if="!filterByAnalytic"></counter-label>
            <counter-label :counter="filteredInvoices.length" label="facture" v-else></counter-label>
          </h2>
        </b-col>
        <b-col>
          <b-form-select
            id="order"
            v-model="selectedOrder"
            @change="onOrderChanged"
          >
            <b-form-select-option
              v-for="order of orders"
              :key="order.id"
              :value="order.id"
            >
              {{ order.name }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col v-if="!paid">
          <b-input type="text" v-model="entityFilter" placeholder="Entrez le nom d'une famille">
          </b-input>
        </b-col>
        <b-col cols="4" v-if="paginate" class="text-right">
          <pagination
            :pages-count="pagesCount"
            :has-next="hasNext"
            :has-previous="hasPrevious"
            :selector="paginationSelector"
            @change="onPageChanged($event)"
            css-style="justify-content: flex-end;"
          >
          </pagination>
        </b-col>
      </b-row>
    </div>
    <div v-if="unpaidAnalytics.length">
      <a class="header-link" href @click.prevent="filterByAnalytic = !filterByAnalytic">
        Filtrer par code analytique
      </a>
    </div>
    <div ref="printMe">
      <div class="hide-here">
        <span v-if="!paid">Factures impayées au {{ today }}</span>
      </div>
      <div v-if="filterByAnalytic">
        <b-row>
          <b-col>
            <check-box-select
              id="school-years-select"
              class="analytics-selector no-print"
              :choices="schoolYears"
              inline
              @changed="selectedSchoolYearsChanged($event)"
            ></check-box-select>
          </b-col>
          <b-col cols="3">
            <b-input type="text" v-model="analyticFilter" placeholder="Entrez le nom d'un analytique">
          </b-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <check-box-select
              id="unpaid-analytic-select"
              class="analytics-selector no-print"
              :choices="unpaidAnalyticChoices"
              inline
              @changed="selectedAnalyticsChanged($event)"
            ></check-box-select>
            <div class="hide-here">
              <div class="label" v-for="analytic of selectedAnalytics" :key="analytic.id">
                {{ analytic.name }}
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div ref="excelMe">
        <table class="table table-striped" v-if="!isLoading(loadingName)">
          <tr>
            <th></th>
            <th>Facturé le</th>
            <th v-if="paid">Payé le</th>
            <th></th>
            <th></th>
            <th class="text-right">Total</th>
            <th class="text-right" v-if="!paid">À payer</th>
          </tr>
          <tr v-for="invoice in filteredInvoices" :key="invoice.id">
            <td>
              <invoice-badge
                :invoice="invoice"
                allow-send-not-paid
                no-amount
                :entity="getEntity(invoice)"
                v-if="showBadge(invoice)"
              ></invoice-badge>
            </td>
            <td>{{ invoice.createdOn | dateToString }}</td>
            <td v-if="paid">{{ invoice.lastPaymentOn | dateToString }}</td>
            <td>
              <router-link :to="getEntityLink(invoice.entity)">
                <span class="family-numbers">{{ invoice.entity.id }}</span>
                {{ invoice.entity.name }}
              </router-link>
            </td>
            <td style="width: 40%;">
              <analytics-detail-view :analytics="invoice.analytics"></analytics-detail-view>
            </td>
            <td class="number">
             {{ invoice.totalPrice | currency }}
            </td>
            <td class="number" v-if="!paid">
             {{ invoice.toBePaidPrice() | currency }}
            </td>
          </tr>
          <tr v-if="!paginate && !paid && !isLoading(loadingName)">
            <th colspan="4"></th>
            <th class="number">
              {{ total | currency }}
            </th>
            <th class="number">
              {{ toBePaidTotal | currency }}
            </th>
          </tr>
        </table>
      </div>
      <loading-gif :loading-name="loadingName"></loading-gif>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import CounterLabel from '@/components/Controls/CounterLabel'
import LoadingGif from '@/components/Controls/LoadingGif'
import Pagination from '@/components/Controls/Pagination'
import CheckBoxSelect from '@/components/Controls/CheckBoxSelect'
import AnalyticsDetailView from '@/components/Accounting/AnalyticsDetailView'
import InvoiceBadge from '@/components/Invoices/InvoiceBadge'
import PageHeader from '@/components/Layout/PageHeader'
import { currency } from '@/filters/texts'
import router from '@/router'
import { makeChoice } from '@/types/base'
import { makeInvoice, makeAnalyticDetail } from '@/types/payments'
import { makeEntity } from '@/types/people'
import { existsIn, distinct } from '@/utils/arrays'
import { BackendApi, openDocument } from '@/utils/http'
import { compareNumbers, compareStrings } from '@/utils/sorting'

export default {
  name: 'invoices-list',
  mixins: [BackendMixin],
  props: {
    paid: Number,
  },
  components: {
    CheckBoxSelect,
    CounterLabel,
    LoadingGif,
    PageHeader,
    InvoiceBadge,
    Pagination,
    AnalyticsDetailView,
  },
  data() {
    return {
      loadingName: 'invoices-to-pay',
      invoices: [],
      objectsCount: 0,
      page: 0,
      hasNext: false,
      hasPrevious: false,
      unpaidAnalytics: [],
      selectedAnalytics: [],
      selectedSchoolYears: [],
      filterByAnalytic: false,
      selectedOrder: 0,
      entitiesMap: new Map(),
      entityFilter: '',
      analyticFilter: '',
    }
  },
  watch: {
    loading: function(newValue, oldValue) {},
    paid: function() {},
    filterByAnalytic: function() {
      if (!this.filterByAnalytic) {
        this.selectedAnalytics = []
      }
    },
  },
  computed: {
    unpaidAnalyticChoices() {
      const selectedSchoolYearIds = this.selectedSchoolYears.map(elt => elt.id)
      let unpaidAnalytics = this.unpaidAnalytics
      if (this.analyticFilter) {
        const analyticFilter = this.analyticFilter.toLowerCase()
        unpaidAnalytics = unpaidAnalytics.filter(
          elt => (
            elt.analyticAccount.analyticAccount.getLabel().toLowerCase().indexOf(analyticFilter) >= 0
          )
        )
      }
      const choices = unpaidAnalytics.filter(
        (elt) => {
          if (selectedSchoolYearIds.length === 0) {
            return true
          } else {
            return existsIn([elt.analyticAccount.schoolYear.id], selectedSchoolYearIds)
          }
        }
      ).sort(
        (elt1, elt2) => {
          let value = compareNumbers(
            elt1.analyticAccount.schoolYear.startYear,
            elt2.analyticAccount.schoolYear.startYear
          )
          if (value === 0) {
            value = compareNumbers(
              elt1.analyticAccount.analyticAccount.order,
              elt2.analyticAccount.analyticAccount.order
            )
          }
          if (value === 0) {
            value = compareStrings(
              elt1.analyticAccount.analyticAccount.name,
              elt2.analyticAccount.analyticAccount.name
            )
          }
          return value
        }
      ).map(
        (elt) => {
          return makeChoice(
            {
              id: elt.analyticAccount.getKey(false),
              name: elt.analyticAccount.getLabel() + ': ' + currency(elt.unpaidTotal),
            }
          )
        }
      )
      return distinct(choices)
    },
    pagesCount() {
      return Math.ceil(this.objectsCount / 100)
    },
    paginationSelector() {
      return ''
    },
    paginate() {
      // Pour les factures non payées : on charge tout sans pagination
      return this.paid
    },
    today() {
      return moment().format('llll')
    },
    toBePaidTotal() {
      return this.filteredInvoices.reduce(
        (acc, curr) => {
          acc += curr.toBePaidPrice()
          return acc
        },
        0
      )
    },
    total() {
      return this.filteredInvoices.reduce(
        (acc, curr) => {
          acc += curr.totalPrice
          return acc
        },
        0
      )
    },
    filteredInvoices() {
      const selectedAnalyticIds = this.selectedAnalytics.map(elt => elt.id)
      const selectedSchoolYearIds = this.selectedSchoolYears.map(elt => elt.id)
      const entityFilter = this.entityFilter.toUpperCase()
      return this.invoices.filter(
        (elt) => {
          if (entityFilter && elt.entity.name.toUpperCase().indexOf(entityFilter) < 0) {
            return false
          }
          if (selectedSchoolYearIds.length > 0) {
            const yearIds = (elt.analytics.map(ana => ana.schoolYear.id))
            if (!existsIn(yearIds, selectedSchoolYearIds)) {
              return false
            }
          }
          if (selectedAnalyticIds.length === 0) {
            return true
          } else {
            const eltAnaIds = (elt.analytics.map(ana => ana.getKey(false)))
            for (const eltAnaId of eltAnaIds) {
              if (existsIn([eltAnaId], selectedAnalyticIds)) {
                return true
              }
            }
            return false
          }
        }
      )
    },
    orders() {
      const choices = [
        makeChoice({ id: 0, name: 'De la plus récente à la plus ancienne', }),
        makeChoice({ id: 1, name: 'De la plus ancienne à la plus récente', })
      ]
      if (!this.paid) {
        choices.push(makeChoice({ id: 2, name: 'Ordre alphabétique', }))
      }
      return choices
    },
    schoolYears() {
      return distinct(this.unpaidAnalytics.map(elt => elt.analyticAccount.schoolYear))
    },
  },
  mounted() {
    this.onLoaded()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    getLinks() {
      let links = []
      if (!this.paid) {
        links = [
          {
            id: 1,
            label: 'Synthèse',
            callback: this.viewUnpaidSynthesis,
            icon: 'fas fa-chart-bar',
            cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
          },
          {
            id: 2,
            label: 'Pdf',
            callback: this.printMe,
            icon: 'fa fa-file-pdf',
            cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
          },
          {
            id: 3,
            label: 'Excel',
            callback: this.excelMe,
            icon: 'fa fa-file-excel',
            cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
          }
        ]
      }
      return links
    },
    onLoaded() {
      if ((this.paginate) && (this.$route.query.page)) {
        this.page = (+this.$route.query.page) || 1
      } else {
        this.page = 1
      }
      if (this.paginate) {
        this.$route.query.page = '' + this.page
      }
      this.loadElements(this.page)
    },
    onPageChanged(event) {
      if (this.page !== event.page) {
        this.page = event.page
        this.loadElements(this.page)
      }
    },
    onOrderChanged() {
      if (this.page !== 1) {
        this.page = 1
        // chargement par le changement de page
      } else {
        this.loadElements(this.page)
      }
    },
    getEntityLink(entity) {
      if (entity.family) {
        return { name: 'families-detail', params: { entityId: '' + entity.id, }, query: { tab: 'invoices', }, }
      } else {
        return { name: 'entities-detail', params: { entityId: '' + entity.id, }, query: { tab: 'invoices', }, }
      }
    },
    async loadElements(page) {
      this.startLoading(this.loadingName)
      this.invoices = []
      let currentPage = page
      this.unpaidAnalytics = []
      while (currentPage > 0) {
        let url = '/api/invoices-list/' + this.paid + '/?page=' + currentPage
        if (this.selectedOrder === 1) {
          url += '&order=asc'
        } else if (this.selectedOrder === 2) {
          url += '&order=alpha'
        }
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.objectsCount = resp.data.count
          this.hasNext = !!resp.data.next
          this.hasPrevious = !!resp.data.previous
          this.invoices = this.invoices.concat(resp.data.results.map(elt => makeInvoice(elt)))
          if (this.paginate) {
            currentPage = 0 // on pagine donc on ne charge qu'une page
          } else {
            // on ne pagine pas, donc on charge tant que la page suivante n'est pas null
            if (this.hasNext) {
              // encore des données : on continue
              currentPage += 1
            } else {
              // plus de données : on s'arrête
              currentPage = 0
            }
          }
        } catch (err) {
          await this.addError(this.getErrorText(err))
          break
        }
      }
      if (!this.paid) {
        await this.loadUnpaidAnalytics()
        await this.loadEntities()
      }
      this.endLoading(this.loadingName)
    },
    async loadEntities() {
      const entitiesMap = new Map()
      let url = '/api/people/retrieve-entities/'
      const data = { entities: this.invoices.map(elt => elt.entity.id), }
      const backendApi = new BackendApi('post', url)
      if (data.entities) {
        try {
          const resp = await backendApi.callApi(data)
          for (const item of resp.data) {
            entitiesMap.set(item.id, makeEntity(item))
          }
        } catch (err) {
          this.addError(this.getErrorText(err))
        }
      }
      this.entitiesMap = entitiesMap
    },
    getEntity(invoice) {
      return this.entitiesMap.get(invoice.entity.id)
    },
    showBadge(invoice) {
      if (this.paid) {
        // Pour les factures payées, on ne charge pas la famille pour info d'envoid donc
        // on peut afficher le badge sans charger au préalable les familles
        return true
      } else {
        return this.getEntity(invoice)
      }
    },
    async loadUnpaidAnalytics() {
      const url = '/api/unpaid-analytics/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        const newAnalytics = resp.data.map(
          elt => {
            return {
              analyticAccount: makeAnalyticDetail(elt),
              unpaidTotal: elt.unpaid_total,
            }
          }
        )
        const existingKeys = this.unpaidAnalytics.map(elt => elt.analyticAccount.getKey())
        for (const newAnalytic of newAnalytics) {
          const index = existingKeys.indexOf(newAnalytic.analyticAccount.getKey())
          if (index >= 0) {
            this.unpaidAnalytics[index].add(newAnalytic)
          } else {
            this.unpaidAnalytics.push(newAnalytic)
          }
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    viewUnpaidSynthesis() {
      router.push({ name: 'unpaid-analytics', })
    },
    async printMe() {
      const docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = 'factures-impayees-pdf-' + moment().format('YYYY-MM-DD-HH-MM-SS')
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'factures-impayees-xls-' + moment().format('YYYY-MM-DD-HH-MM-SS')
      const docContent = this.$refs.excelMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    selectedSchoolYearsChanged(selected) {
      this.selectedSchoolYears = selected.choices
    },
    selectedAnalyticsChanged(selected) {
      this.selectedAnalytics = selected.choices
    },
  },
}
</script>

<style lang="less">
.header-link {
  display: block;
}
.analytics-selector {
  margin: 5px 0;
  padding: 5px;
  background: #e0e0e0;
}
.number {
  text-align: right;
}
</style>
